import React from "react";
import "./services.css";
import {tapeImage1, tapeImage2, tapeImage3} from './tape-images'

function services() {
  return (
    <>
      <h1 className="services_header">Services</h1>
      <section className="availability">
        <img className='serviceImg' src={tapeImage1} alt='tapeImage1'></img>
        <div className='service_description'>
          <h2 className="availability_header">LET'S WORK TOGETHER</h2>
          <p>Mikenzie is available to travel world wide for projects including : </p>
          <ul className="lists">
            <li>Collaborations</li>
            <li>Brand Content Creation</li>
            <li>Social Media Promotion</li>
            <li>Wall Art Prints</li>
          </ul>
        </div>
      </section>
      <section className="for_photographers">
        <div className='service_description'>
          <h2 className="availability_header">FOR PHOTOGRAPHERS</h2>
          <p>Want to learn more about photography?</p>
          <ul className="lists">
            <li>Editing Tutorials</li>
            <li>Lightroom Presets</li>
            <li>Basic Courses</li>
          </ul>
        </div>
        <img src={tapeImage2} alt='tapeImage2'></img>
      </section>
      <section className="courses">
        <img src={tapeImage3} alt='tapeImage3'></img>
        <div className='service_description'>
          <h2 className="photography_courses">PRIVATE PHOTOGRAPHY COURSES</h2>
          <p>
            Mikenzie is available to come to you, travel with you anywhere, or do
            virtual classes to be your personal instructor. Mikenzie will give you
            tips and tricks based on your personal style, anywhere in the world.
            If interested in courses, reach out to Mikenzie.
          </p>
        </div>
      </section>
    </>
  )
}

export default services;
