const prices = {
    weddingPrices: [
        {
            number:'1',
            title: 'COLLECTION #1 : $2,600',
            list: [
                '6 hours',
                'Edited photos',
                'Personal printing rights',
                '72 hour sneak peek',
                'USB',
                'Access to online gallery'
            ]
        },
        {
            number: '2',
            title: 'COLLECTION #2 : $3,600',
            list: [
                '9 hours',
                '1 hour added session (engagement, adventure, etc.)',
                'Unlimited printing rights',
                '48 hour sneak peek',
                'Edited photos',
                'USB',
                'Access to online gallery',
                'Pre-wedding planning consult'
            ]
        },
        {
            number: '3',
            title: 'COLLECTION #3 : $4,200',
            list: [
                'All day',
                '1 hour added session (engagement, adventure, etc.)',
                'Edited photos',
                'Unlimited printing rights',
                '48 hour sneak peek',
                'USB + Prints',
                'Access to online gallery',
                'Pre-wedding planning consult'
            ]
        },
        {
            number: '4',
            title: 'Additional Options:',
            list: [
                '$350/hour for added hours',
                'Travel fees over 50 miles (from Fenton, MI)',
                'Customized package (contact for more information)'

            ]
        },
    ],
    intimateSessionPrices: [
        {
            number: '1',
            title: 'SMALL WEDDINGS :',
            list: [
                'Partial/ Full day options',
                'Complimentary engagement session (1.5 hours)',
                'Location/planning assistance',
                'Access to online Gallery',
                'High resolution fully edited photos',
                'Personal printing rights'
            ],
            startingPrice: '$2,000'
        },
        {
            number: '2',
            title: 'ENGAGEMENTS :',
            list: [
                '2 hours (option for less time)',
                'Detailed questionnaire',
                'Location recommendation (option for more than one location)',
                'Access to online gallery',
                '50 High resolution fully edited photos',
                'Personal printing rights',
                'Option for multiple outfits'
            ],
            startingPrice: '$450'
        },
        {
            number: '3',
            title: 'ADVENTURE SESSIONS :',
            list: [
                '2 hours',
                'Detailed questionnaire',
                'Location recommendation (option for more than one location)',
                'Access to online gallery',
                '50 High resolution fully edited photos',
                'Personal printing rights'
            ],
            startingPrice: '$450'
        },
        {
            number: '4',
            title: 'SURPRISE PROPOSALS :',
            list: [
                '1 hour',
                'Location of your choice',
                '35 edited photos',
                'Access to online gallery'
            ],
            startingPrice: '$300'
        }
    ]
}

export default prices