import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import './login.css'
import { useAuth } from '../../contexts/AuthContext'

function LoginComponent() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            return history.push('/admin')
        } catch (e) {
            setError('Failed to login')
            console.log(error)
        }
        
        setLoading(false)
    }

    return(
        <section className='login'>
            <h1 className='login__header'>
                Log In
            </h1>
            <form className='login__form' onSubmit={handleSubmit}>
                <div className='login__form--formGroup'>
                    <label htmlFor='name'>Email: </label>
                    <input id='email' type='email' ref={emailRef} />
                </div>
                <div className='login__form--formGroup'>
                    <label htmlFor='password' required >Password: </label>
                    <input id='password' type='password' ref={passwordRef} />
                </div>
                <div className='login__form--formGroup'>
                    <input disabled={loading} type='submit' value='Submit' />
                </div>
            </form>
        </section>
    )
}

export default LoginComponent