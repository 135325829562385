import React, { useState } from 'react'
import './footer.css'
import Social from '../SocialComponent/social'


const Footer = () => {

    let [email] = useState('kressmik@yahoo.com')
    let [year] = useState(new Date().getFullYear())

    return(
        <footer>
            <div className='footer_header'>
                <div className='email'>
                    <span>Contact:</span>
                    <span>
                        <a 
                            href={'mailto:' + email} 
                            title={email}
                        >
                            {email}
                        </a>
                    </span>
                </div>
                <h2>
                    <span>Mikenzie</span>
                    <span>Kress</span>
                </h2>
                <div className='copyright'>
                    <span>Copyright</span>
                    <span>Mikenzie Kress {year}</span>
                </div>
            </div>
            <div className='footer_socials'>
                <div className='footer_socials__info'>
                        Get in touch on: Instagram
                </div>
                <Social />
            </div>
        </footer>
    )

}

export default Footer