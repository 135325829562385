import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import {NavLink, Link} from 'react-router-dom'
import './Navbar.css'
import Social from '../SocialComponent/social'

class Navbar extends Component {
    constructor(props){
        super(props)
        this.state = {
            menuIsToggled: true,
            links: [
                {name:'Home',link:'/'},
                {name:'Photos',link:'/photos'},
                {name:'Pricing',link:'/pricing'},
                {name:'Services',link:'/services'},
                {name:'Contact',link:'/contact'},
                {name:'FAQ',link:'/faq'}
                
            ]
        }

        this.handleMenuToggle = this.handleMenuToggle.bind(this)
    }

    handleMenuToggle() {
        this.setState(state => ({
            menuIsToggled: !state.menuIsToggled
        }))

        let nav = document.querySelector('nav')

        this.state.menuIsToggled ? nav.classList.add('show') : nav.classList.remove('show')

    }


    render() {
        return (
            <header>
                <div className='title_bar'>
                    <div className='socials'>
                        <Social />
                        <div className='mobileMenu' onClick={this.handleMenuToggle}>Menu <FontAwesomeIcon icon={faBars}></FontAwesomeIcon></div>
                    </div>
                    <h1>
                        <Link to="/">
                            <span>Mikenzie</span>
                            <br></br>
                            <span>Kress</span>
                        </Link>
                    </h1>
                    <div className='description'>Photography</div>
                </div>
                <nav>
                    <ul>
                        {
                            this.state.links.map((link) => {
                                return <li key={link.name}><NavLink exact onClick={this.handleMenuToggle} to={link.link}>{link.name}</NavLink></li>
                            })
                        }
                    </ul>
                </nav>
            </header>
        )
    }

}

export default Navbar