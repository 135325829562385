import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from 'react-router-dom'
import AddPhoto from './addPhoto'
import EditPhoto from './editPhoto'
import './admin.css'

function AdminComponent() {
    const [editing, setEditing] = useState(false)
    const [adding, setAdding] = useState(false)
    const [toolSelected, setToolSelected] = useState(true)
    const [error, setError] = useState("")
    const { currentUser, logout } = useAuth()
    const history = useHistory()
    
    async function handleLogout() {
        try {
            await logout()
            return history.push("/login")
        } catch(e) {
            setError("Failed to log out")
            console.log(error)
        }
    }

    function handleEditing(){
        if(adding){setAdding(false)}
        setEditing(true)
        setToolSelected(true)
    }

    function handleAdding() {
        if(editing){setEditing(false)}
        setAdding(true)
        setToolSelected(true)
    }

    return(
        <>
            <h1 className='admin__header'>
                Admin: {currentUser.email}
            </h1>
            <section className='adminTools'>
                <button onClick={handleEditing}>Edit Photos</button>
                <button onClick={handleAdding}>Add Photo</button>
                <button onClick={handleLogout}>Log out</button>
            </section>
            <section className='adminTool'>
                {
                    toolSelected && adding ? <AddPhoto /> :
                    toolSelected && editing ? <EditPhoto /> : 
                    ''
                }
            </section>
        </>
    )
}

export default AdminComponent