import React, { useRef,useState } from 'react'
import { storage, firestore } from '../../firebase'

export default function AddPhoto() {
    const orderRef = useRef()
    const addFormRef = useRef()
    const [fileUrl, setFileUrl] = useState(null)
    const [name, setName ] = useState('')
    const [loading, setLoading] = useState(true)
    const [submitLabael,setSubmitLabel] = useState('Submit')

    async function onFileChange(e) {
        const file = e.target.files[0]
        const storageRef = storage.ref('photos/')
        const fileRef = storageRef.child(file.name)

        setSubmitLabel('Loading...')
        setName(file.name)
        await fileRef.put(file)
        setFileUrl(
            await fileRef.getDownloadURL()
                .then((response) => {
                    return response
                }, (err) => {
                    console.error(err)
                })
        )
        setLoading(false)
        setSubmitLabel('Submit')
    }

    async function onSubmit(e) {
        e.preventDefault()
        const firestoreRef = firestore.collection('/photos')
        const photoObj = {
            name: name,
            height: 3,
            width: 2,
            order: parseInt(orderRef.current.value),
            src: fileUrl
        }

        await firestoreRef.add(photoObj)
            .catch((err) => {
                console.log(err)
            })

        addFormRef.current.reset()
    }

    return (
        <>
            <h1>Add Photo</h1>
            <form ref={addFormRef} id='adminFormAdd' onSubmit={onSubmit}>
                <label htmlFor='order'>Order: </label>
                <input ref={orderRef} name='order' type='number' required/>
                <input type="file" onChange={onFileChange} required/>
                <input disabled={loading} type='submit' value={submitLabael} />
            </form>
        </>
    )
}
