import React, { Component } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import './home.css'

import image1 from '../../img/IMG_8378.jpg'
import image2 from '../../img/IMG_8385.jpg'
import image3 from '../../img/IMG_8398.jpg'
import image4 from '../../img/IMG_9647.jpg'
import image5 from '../../img/IMG_9838.jpg'
import image6 from '../../img/IMG_9840.jpg'
import mikenzie from '../../img/mikenzie.jpg'
import { Link } from 'react-router-dom'

class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mikenzie_photo: mikenzie,
            carousel: {
                images: [
                    {name: 'Image1', src: image1},
                    {name: 'Image2', src: image2},
                    {name: 'Image3', src: image3},
                    {name: 'Image4', src: image4},
                    {name: 'Image5', src: image5},
                    {name: 'Image6', src: image6}
                ],
                settings1: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    autoplay: true,
                    autoplayspeed: 500,
                    responsive: [
                        {
                          breakpoint: 1145,
                          settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                          }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                          }
                        },
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                          }
                        }
                      ]
                }
            },
            recentWork: [
                {name: 'Image4', src: image4},
                {name: 'Image5', src: image5},
                {name: 'Image6', src: image1}
            ],
            settings2: {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplayspeed: 500,
                responsive: [
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      }
                    }
                  ]
            }
        }
    }

    render() {
        return(
            <section id='home' className='homeComponent'>
                <div className='slider-container'>
                    <Slider {...this.state.carousel.settings1}>
                        {this.state.carousel.images.map(image => {
                            return(
                                <div key={image.name} className='slider__slide'>
                                    <img className='slider__container-img' src={image.src} alt={image.name}></img>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <p className='homeComponent__quote'>
                    <span>" The earth has</span>
                    <span>music for those</span>
                    <span>who listen "</span>
                </p>
                <div className='homeComponent__bio'>
                    <img className='homeComponent__bio-image' src={this.state.mikenzie_photo} alt='Mikenzie'></img>
                    <div className='homeComponent__bio-description'>
                        <p>
                            Hi, this is me. The face behind Mikenzie Kress Photography. I’m from a small town in Michigan,
                            USA, but find myself all over the world taking pictures. I have had a camera in my hands for as
                            long as I can remember. I started off taking pictures of my yard and the streets of my rural town.
                            At 18, my passion for traveling brought me to the other side of the world, solo. I brought my camera 
                            with me to capture what I coudn’t put into words and a new passion was born. From then on, I fell in 
                            love with capturing landscapes, natural phenomena, all my adventures, and more recently, the love 
                            between two human beings.
                        </p>
                        <p>
                            My passion to be with nature along with my love of art, adventure and exploring is what I think categorizes 
                            my photography and fuels me to seek out and find the beauty created by the natural world. I am inspired by
                            landscapes and finding all the important elements that connect humans with nature. I love being outdoors. 
                            I love to rock climb, and hike the mountains, but you will also find me doing yoga on the beach, cooking up 
                            some vegan food, and spending my summers in the water. 
                        </p>
                        <p>
                            Feel free to send me a message with any questions, and I’ll get back to you. Thanks for stopping by! 
                        </p>
                        <Link to='/contact'>
                            <button>Contact</button>
                        </Link>
                    </div>
                </div>
                <div className='homeComponent__recentWork'>
                    <h2 className='homeComponent__recentWork-header'>
                        - Recent Work -
                    </h2>
                    <div className='homeComponent__recentWork-images'>
                        {
                            this.state.recentWork.map((image) => {
                                return(
                                    <img key={image.name} src={image.src} alt={image.name}></img>
                                )
                            })
                        }
                    </div>
                </div>
            </section>   
        )
    }

}

export default Home