import React  from 'react'
import Gallery from 'react-photo-gallery'
import useFirestore from './photos'
import './photos.css'

const Photos = () => {

    const { docs } = useFirestore('photos')

    return(
        <>
            <h1 className='photosComponent__header'>
                Photos
            </h1>
            <div className='photosComponent__images'>
                <Gallery photos={ docs } direction={'row'} />
            </div>
        </>
            
    )
}

export default Photos