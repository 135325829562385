import FirebaseService from '../../services/firebase.service'
import { useState, useEffect } from "react"


const useFirestore = (collection) => {
    const [docs, setDocs] = useState([])

    useEffect(() => {
        const unsubscribe = FirebaseService.getCollection(collection)
            .orderBy('order','asc')
            .onSnapshot((snap) => {
                let documents = []
                snap.forEach(doc => {
                    documents.push({
                        ...doc.data(),
                        id: doc.id,
                        key: doc.id,
                        alt: doc.data().name})
                })
                setDocs(documents)
            })

        return () => unsubscribe()

    },[collection])

    return { docs }
}

export default useFirestore