
const questions = [
    {
        question:'How far should I book in advance?',
        answer:"As soon as possible! Dates are reserved on a first come first serve basis. It's best to secure the date as soon as you know it."
    },
    {
        question:"How many images will I receive?",
        answer:"You will have full access to all the pictures via online gallery, but the actual amount varies depending on the wedding, or type of shoot you want. Typically 75-100 pictures an hour, but the amount of fully edited pictures depends on the collection. "
    },
    {
        question:"Do you require a deposit?",
        answer:"Yes, 25% of the cost, non-refundable."
    },
    {
        question:"How long do I have to pay for the package?",
        answer:"You have 3 weeks before the date of the shoot to pay in full. "
    },
    {
        question:"Do you offer payment plans?",
        answer:"Yes, I will work you on a plan depending on specific requests."
    },
    {
        question:"Do you travel and what is the cost?",
        answer:"Yes, I will be willing to travel just about anywhere. The cost will be dependent on location and time of year. "
    },
    {
        question:"What if I don't know how to pose?!",
        answer:"Don’t worry about that!  During every session I will give you direction. You will never have to think about how to stand, etc. That's why you hire me! "
    },
    {
        question:"What if I don't see exactly what I want in a package?",
        answer:"No worries! I am more than happy to customize something for you! Let me know what you’re looking for, and we will work something out. "
    },
    {
        question:"Do you take photoes of LGBTQ+ couples?",
        answer:"Yes, absolutely! I strongly believe that everybody deserves love and has the right to be with who they love regardless of gender. I would love to be a part of your LGBTQ+ shoot! "
    },
    {
        question:"When will I receive the fully edited photos?",
        answer:"Depending on the package, you will receive a sneak peek within 48-72 hours. All the photos should be received within 2 weeks. "
    }
]

export default questions