import React from 'react'
import prices from './prices'
import './pricing.css'
import img1 from '../../img/10F637C3-4DE4-4A4B-A13E-2FF1E720BDB1.JPG'
import img2 from '../../img/DSC08386.JPG'
import img3 from '../../img/fullsizeoutput_1c7c.JPG'
import { Link } from 'react-router-dom'

function pricing() {
    return (
        <>
            <h1 className='pricing__header'>Pricing</h1>
            <section className='pricing__intro'>
                <p>
                    "
                        Have a wedding, engagement or any other life event coming up? Or any other project in mind? 
                        Let’s collaborate! If you’re the type of person who cherishes life, 
                        the present moment, and storytelling, you're in the right place! 
                    "
                </p>
                <div className='pricing__intro-section'>
                    <img src={img1} alt='pricing__intro-section-img'></img>
                    <div>
                        <h2>Capturing your story</h2>
                        <sub>~ you won't want to miss it ~</sub>
                        <p>
                            There are so many parts to a story, 
                            from the moment you met, to popping the 
                            question, to creating your legacy together. 
                            I’ll be here to capture anything you missed. 
                            I’m here to fill in any gaps, from the faces 
                            of the community who raised you, to the 
                            landscapes themselves. I’ll be sure to tell your story. 
                        </p>
                    </div>
                </div>
                <div className='pricing__intro-section'>
                    <img src={img2} alt='pricing__intro-section-img'></img>
                    <div>
                        <h2>A photographer who understands you</h2>
                        <sub>~ be you ~</sub>
                        <p>
                            I will make it my job to understand you as 
                            individuals and as one. From what makes you 
                            smile, what pulls at your heart, and how to 
                            capture your special moment. 
                        </p>
                    </div>
                </div>
                <div className='pricing__intro-section'>
                <img src={img3} alt='pricing__intro-section-img'></img>
                    <div>
                        <h2>Stay in the moment</h2>
                        <sub>~ be present ~</sub>
                        <p>
                            No matter what stage you’re in, it’s a 
                            special day; make sure you can stay in the 
                            moment. Your day can fly by in moments if 
                            you let it. I will help you slow down and 
                            bring you to the present moment and keep 
                            you grounded. 
                        </p>
                    </div>
                </div>
                </section>

                <section className='pricing__prints-price'>
                <h2>PRINTS</h2>

                <p>
                    Get your favorite photo by Mikenzie as a print for your home. 
                    World Wide shipping is available. All photos are available for 
                    prints. Please <Link to='/contact'>contact</Link> Mikenzie with image name, desired size 
                    and material. 
                </p>
                    <h3 className='pricing__prints-availableopt'> Available options : </h3>
                    <ul>
                        <li> Standard prints : matte and gloss </li>
                        <li> Canvas : photo wrapped, black wrapped, white wrapped </li>
                        <li> Acrylic </li>
                        <li> Metal: gloss and matte </li>
                    </ul>
                </section>

            <section className='pricing__weddings'>
                <h2>WEDDING COLLECTIONS</h2>
                <sub>~ invest in your memories ~</sub>
                <div className='pricing__weddings-prices'>
                    {prices.weddingPrices.map(section => {
                        return(
                            <div key={section.number} className={'pricing__weddings-price'}>
                                <h3>{section.title}</h3>
                                <ul>
                                    {section.list.map(item => {
                                        return(
                                            <li key={item}>{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </section>
            <section className='pricing__intimate'>
                <h2>INTIMATE SESSIONS</h2>
                <sub>~ enjoy the "little" things ~</sub>
                <div className='pricing__intimate-prices'>
                    {prices.intimateSessionPrices.map(price => {
                        return(
                            <div key={price.number} className={'pricing__intimate-price'}>
                                <h3>{price.title}</h3>
                                <ul>
                                    {price.list.map(item => {
                                        return(
                                            <li key={item}>{item}</li>
                                        )
                                    })}
                                </ul>
                                <div className='pricing__intimate-startingPrice'>
                                    <p>
                                        Starting at: {price.startingPrice}
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>

        </>
    )
}

export default pricing
