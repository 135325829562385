import { firestore, storage } from "../firebase"

const db = firestore.collection('/photos')
const storageRef = storage.ref()

class FirebaseService {
    getCollection(collection){
        return firestore.collection(collection)
    }
    
    getDoc(doc) {
        return db.doc(doc)
    }

    getall() {
        return db
    }

    create(photo){
        return db.add(photo)
    }

    update(id, value) {
        return db.doc(id).update(value)
    }

    async delete(id, photoName) {
        return await db.doc(id).delete().then( async () => {
            await storageRef.child(`/photos/${photoName}`).delete()
        })
    }
}

export default new FirebaseService()