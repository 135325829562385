import React, { useRef,useState } from 'react'
import './contact.css'
import contactImage from '../../img/unnamed.png'
import sendEmail from '../../services/emailjs.service'
import Social from '../SocialComponent/social'

const Contact = () => {
    const nameRef = useRef()
    const emailRef = useRef()
    const subjectRef = useRef()
    const messageRef = useRef()
    const formRef = useRef()
    const [loading, setLoading] = useState(false)
    const [submitLabel, setSubmitLabel] = useState('Submit')
    const [alert, setAlert] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [error, setError] = useState(false)
    const MikenzieInfo = {
        email: 'kressmik@yahoo.com',
        img: contactImage
    }

    async function handleSubmit(e) {
        try{
            e.preventDefault()

            setLoading(true)
            setSubmitLabel('Loading...')

            const outgoingEmail = {
                name: nameRef.current.value,
                email: emailRef.current.value,
                message: messageRef.current.value,
                subject: subjectRef.current.value
            }

            await sendEmail(outgoingEmail)
                .then((response) => {
                    setAlertMsg('Success! Message has sent')
                    setAlert(true)
                    console.log(`Success! ${response.status} ${response.text}`)
                })

            
        }catch(err){
            setAlertMsg('Error! Message did not send')
            setError(true)
            setAlert(true)
            console.error(err)
        }

        setLoading(false)
        setSubmitLabel('Submit')
        formRef.current.reset()

        setTimeout(() => {
            setAlert(false)
        },5000)
    }

    return(
        <>
            <h1 className='contactComponent__header'>
                Contact Mikenzie
            </h1>
            
            <form ref={formRef} className='contactComponent__form' onSubmit={handleSubmit}>
                <div className='contactComponent__contactInfo'>
                    <div className='contactComponent__contactInfo-info'>
                        <h1>Let's Hang!</h1>
                        <p>Mikenzie Kress Photography</p>
                        <a href={'mailto:' + MikenzieInfo.email}>{MikenzieInfo.email}</a>
                        <Social />
                    </div>
                    <img src={MikenzieInfo.img} alt='contactImage'></img>
                </div>
                <div className='contactComponent__form--formInputs'>
                    <div className='contactComponent__form--formGroup'>
                        <label htmlFor='name'>Name*</label>
                        <input ref={nameRef} required name='name' id='name' type='text' />
                    </div>
                    <div className='contactComponent__form--formGroup'>
                        <label htmlFor='email'>E-mail*</label>
                        <input ref={emailRef} required name='email' id='email' type='email' />
                    </div>
                    <div className='contactComponent__form--formGroup'>
                        <label htmlFor='subject'>Subject*</label>
                        <input ref={subjectRef} required name='subject' id='subject' type='text' />
                    </div>
                    <div className='contactComponent__form--formGroup'>
                        <label htmlFor='message'>Message*</label>
                        <textarea ref={messageRef} required name='message' id='message' />
                    </div>
                    <div className='contactComponent__form--formGroup'>
                        <input disabled={loading} type='submit' value={submitLabel} />
                    </div>
                    {alert && <div className={error?'alert error':'alert success'}>{alertMsg}</div>}                
                </div>
            </form>
        </>
    )

}

export default Contact