import { init, send } from 'emailjs-com'

init(process.env.REACT_APP_EMAILJS_USER_ID)

const sendEmail = async (templateParams) => {

   return await send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID, 
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
      templateParams
   )
}

export default sendEmail