import React, { useRef,useState } from 'react'
import FirebaseService from '../../services/firebase.service'
import useFirestore from '../PhotosComponent/photos'

export default function EditPhoto() {
    const heightRef = useRef()
    const widthRef = useRef()
    const orderRef = useRef()
    const [modal, setModal] = useState(false)
    const [ currentDocID, setCurrentDocID ] = useState('')
    const [document, setDocument ] = useState({})
    const { docs } = useFirestore('photos')

    async function deletePic(id, photoName) {
        await FirebaseService.delete(id, photoName)
        .catch((err) => {
            console.log(err)
        })
    }

    async function openEditModal(id) {
        await FirebaseService.getDoc(id).get()
        .then((doc) => {
            setDocument({...doc.data()})
        })
        .catch((err) => {
            console.log(err)
        })
        
        setCurrentDocID(id)
        setModal(true)
    }

    function closeEditModal() {
        setModal(false)
    }

    async function editPic(id) {
        let newVal = {
            height: parseInt(heightRef.current.value),
            width: parseInt(widthRef.current.value),
            order: parseInt(orderRef.current.value)
        }
        await FirebaseService.update(id, newVal)
        closeEditModal()
    }

    return (
        <>
            <h1>Edit Photos</h1>
            {docs.map(doc => {
                return (
                    <div className='editPhoto' key={doc.id}>
                        <img className='editPhoto__photo' alt={doc.name} src={doc.src}></img>
                        <div className='editPhoto__description'>
                            <div>Height: {doc.height}</div>
                            <div>Width: {doc.width}</div>
                            <div>Order: {doc.order}</div>
                            <button onClick={() => openEditModal(doc.id)}>Edit</button>
                            <button onClick={() => deletePic(doc.id, doc.name)}>Delete</button>
                        </div>
                    </div>
                )
            })}

            {modal && 
                <div className='editModal'>
                    <div className='editModal__content'>
                        <h2>{document.name}</h2>
                        <form className='editModal__content-form'>
                            <label htmlFor='height' >Height: </label>
                            <input ref={heightRef} type='number' name='height' defaultValue={document.height} />
                            <label htmlFor='width' >Width: </label>
                            <input ref={widthRef} type='number' name='width' defaultValue={document.width} />
                            <label htmlFor='order' >Order: </label>
                            <input ref={orderRef} type='number' name='order' defaultValue={document.order} />
                        </form>
                        <button onClick={() => {editPic(currentDocID)}}>Save</button>
                        <button onClick={closeEditModal} >Cancel</button>
                    </div>
                </div>
            }
        </>
    )
}
