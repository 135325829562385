import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'

class Social extends Component {
    constructor(props){
        super(props)
        this.state = {
            instegram: 'https://www.instagram.com/mikenziekress_photography/',
            facebook: 'https://www.facebook.com/mikenzie.kress'
        }
    }

    render() {
        return (
            <div className='social'>
                <a target='_blank' rel='noreferrer' href={this.state.instegram}>
                    <FontAwesomeIcon icon={faInstagramSquare} />
                </a>
                {/* <a target='_blank' rel='noreferrer' href={this.state.facebook}>
                    <FontAwesomeIcon icon={faFacebookSquare} />
                </a>                         */}
            </div>
        )
    }


}

export default Social