import React from 'react'
import questions from './questions'
import './faq.css'

function faq() {
    return (
        <>
            <div className='faq__header'>
                <h1>Frequently Asked Questions</h1>
                <p>
                    Got a question? I'm here to answer! 
                    If you don't see your question here, 
                    drop me a line on my contact page!
                </p>
            </div>
            <div className='faq__cards'>
                {questions.map(question => {
                    return(
                        <div key={question.question} className='faq__cards-card'>
                            <h2>{question.question}</h2>
                            <p>
                                {question.answer}
                            </p>
                        </div>
                    )
                })}
            </div>
            <p className='faq__footerQuote'>
                If you don't see your question here, 
                don't hesitate to drop me a line on my 
                contact page!
            </p>
        </>
    )
}

export default faq
