import './App.css';
import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation} from "react-router-dom"
import NavBar from './components/NavbarComponent'
import home from './components/HomeComponent'
import contact from './components/ContactComponent'
import faq from './components/FaqComponent'
import pricing from './components/PricingComponent'
import Footer from './components/FooterComponent'
import photos from './components/PhotosComponent'
import login from './components/LoginComponent'
import admin from './components/AdminComponent'
import services from './components/ServiceComponent'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './PrivateRoute'

function App() {

  function ScollToTop() {
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;;
  }

  const routes = [
    {path:'/photos',route:photos},
    {path:'/contact',route:contact},
    {path: '/faq',route:faq},
    {path: '/pricing',route:pricing},
    {path: '/login',route:login},
    {path: '/services',route:services},
    {path: '/**' ,route:home}
  ]

  return (
      <Router>
        <ScollToTop />
        <AuthProvider>
          <NavBar />
            <main>
              <Switch>
                <PrivateRoute key='/admin' path='/admin' component={admin} />
                <Route key='/' exact path='/' component={home} />
                {
                  routes.map((route) => {
                    return <Route key={route.path} path={route.path} component={route.route} />
                  })
                }
              </Switch>
            </main>
          <Footer />
        </AuthProvider>
      </Router>    
  )
}

export default App;